import Vue from "vue";
import App from "./App.vue";
import store from "./store/store";
import vuetify from "./plugins/vuetify";
import router from "./router";
import { ACTION_IS_LOGGED_IN } from "./store/constants/user";
import axios from "./services/axios.service";
import VuePapaParse from "vue-papa-parse";
import { getCountries } from "@finraxltd/finrax-frontend-common";

Vue.prototype.$GetCountries = getCountries();

//Services
import { TokenService } from "./services/storage.service";
//VueClipboard
import VueClipboard from "vue-clipboard2";
//VeeValidate
import VeeValidate, { Validator } from "vee-validate";

//Moment.js
var moment = require("moment");
Vue.prototype.moment = moment;

if (TokenService.getConfig("email") && TokenService.getConfig("token")) {
  axios.setHeaders();
  store.dispatch(ACTION_IS_LOGGED_IN, true);
}

Vue.use(VueClipboard);
Vue.use(VuePapaParse);

const dictionary = {
  en: {
    custom: {
      loginEmail: {
        required: "Email is required",
        email: "Please enter a valid email address"
      },
      loginPass: {
        required: "Password is required"
      },
      emailUser: {
        required: "Email is required",
        email: "Please enter a valid email address"
      },
      invoiceNumber: {
        required: "Invoice number is required"
      },
      bulkInvoiceNumber: {
        required: "First invoice number is required",
        numeric: "Only numbers allowed"
      },
      token: {
        required: "Token is required",
        numeric: "Only numbers allowed",
        length: "2FA must be 6 digits"
      },
      authentication: {
        required: "Code is required",
        numeric: "Only numbers allowed",
        length: "2FA must be 6 digits"
      },
      twoFactor: {
        numeric: "Only numbers allowed",
        length: "2FA must be 6 digits"
      },
      twoFactorCode: {
        required: "Two-factor code is required",
        numeric: "Must be 6 digits",
        length: "Must be 6 digits"
      },
      userPassword: {
        required: "Password is required",
        regex: "At least one uppercase and number",
        min: "At least 8 characters"
      },
      oldPassword: {
        required: "Old password is required",
        regex: "At least one uppercase and number",
        min: "At least 8 characters"
      },
      confirmationPassword: {
        required: "Confirm Password is required",
        is: "Password doesn't match"
      },
      depositCommission: {
        required: "Deposit commission is required",
        decimal: "Must be a number that contains up to two decimal digits",
        min_value: "0 is the minimum commission",
        max_value: "Maximum deposit commission is 100"
      },
      withdrawalCommission: {
        required: "Withdrawal commission is required",
        decimal: "Must be a number that contains up to two decimal digits",
        min_value: "0 is the minimum commission",
        max_value: "Maximum withdrawal commission is 100"
      },
      transferId: {
        required: "Transfer ID is required."
      },
      processSearchId: {
        regex: "String must be a valid UUID."
      },
      searchClientAccountId: {
        regex: "Must be a valid UUID."
      },
      ledgerOrgName: {
        required: "Organisation name is required"
      },
      ledgerOrgId: {
        required: "Organisation ID is required"
      },
      organisation: {
        required: "Organisation is required"
      },
      business: {
        required: "Organisation is required"
      },
      creditAmount: {
        required: "Credit amount is required",
        decimal: "Only numbers with up to 8 decimal digits allowed"
      },
      debitAmount: {
        required: "Debit amount is required",
        decimal: "Only numbers with up to 8 decimal digits allowed"
      },
      orderReference: {
        required: "Order reference is required",
        regex: "Order reference must be an UUID"
      },
      topUpAmount: {
        required: "Amount is required",
        decimal: "Only numbers with up to 8 decimal digits allowed"
      },
      commissionFeePercent: {
        required: "Commission fee percent is required",
        decimal: "Must be a number that contains up to two decimal digits",
        min_value: "0 is the minimum commission percent",
        max_value: "Maximum commission percent is 100"
      },
      movementType: {
        required: "Movement type is require"
      },
      label: {
        required: "Label is required"
      },
      email: {
        required: "Email is required"
      },
      name: {
        required: "Name is required"
      },
      travelRuleName: {
        required: "Travel rule name is required"
      },
      address: {
        required: "Address is required"
      },
      registrationNumber: {
        required: "Registration number is required"
      },
      enabledFiatSettlementCurrencies: {
        required: "At least one fiat settlement currency is required"
      }
    }
  }
};

Validator.localize(dictionary);
Vue.use(VeeValidate, {
  fieldsBagName: "vvFields"
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,

  render: h => h(App)
}).$mount("#app");
